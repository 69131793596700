<template>
  <div class="wrapper">
    <div class="section-testimonials-1">
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-size-50 md-small-size-100
              mx-auto
              text-center
            "
          >
            <h2 class="title">Our Clients Love Us</h2>
            <h5 class="description"></h5>
          </div>
        </div>
        <div class="md-layout justify-content-center">
          <div class="md-layout-item md-size-33 md-small-size-100 "
          v-for="tstm in testimonials"
          :key="tstm.GiverName"
          >
            <testimonial-card>
              <md-icon slot="icon"> format_quote </md-icon>
              <template slot="cardContent">
                <h5 class="card-description">
                  {{tstm.Content}}
                </h5>
              </template>
              <template slot="cardAction">
                <h4 class="card-title">{{tstm.GiverName}}</h4>
                <h6 class="card-category">
                  {{tstm.Course}}
                </h6>
              </template>
            </testimonial-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TestimonialCard } from "@/components";

export default {
  name: "Testimonial",
  components: {
    TestimonialCard,
  },
  data() {
    return {
      testimonials:[
        {
          Content:'"Diving was never something I considered adding to my bucket list, but thanks to Bubble Divers I am now excited to experience more of what our beautiful oceans are hiding underneath the surface. Jomé was patient and utterly professional during my introductory session and not once during my Discover Scuba Diving experience was I worried that something might go horribly wrong. I can highly recommend Bubble Divers for your dive training, PADI certification or DSD."',
          GiverName:"Jacques Knipe",
          Course:"Discover Scuba Diving"
        }
      ]

      
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.mt-0 {
  margin-top: 0 !important;
}
</style>
