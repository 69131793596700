<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter clear-filter main-banner"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1 style="margin-bottom: -10px">Bubble Divers</h1>
              <h2 class="mt-0">Dive School</h2>
            </div>
            <span class="pro-badge"> South Africa </span> -->
          </div>
        </div>
      </div>
    </parallax>

    <div id="main-panel" class="main main-raised">
      <div class="section pt-0">
        <div>
          <div class="container">
            <h3 class="title text-center">Discover a whole new world!</h3>
            <div class="md-layout md-alignment-top-center">
              <div
                class="
                  md-layout-item
                  md-xlarge-size-33
                  md-large-size-33
                  md-medium-size-33
                  md-small-size-100
                "
                v-for="crd in infoCards"
                :key="crd.title"
              >
                <full-bg-card :card-image="crd.bgImage" :styles="crd.styles">
                  <template slot="cardContent">
                    <a href="javascript:void(0)">
                      <h3 class="card-title">
                        {{ crd.title }}
                      </h3>
                    </a>
                    <p class="card-description">
                      {{ crd.body }}
                    </p>
                    <md-button
                      href="/#contact-us"
                      class="md-primary md-round"
                    >
                      <md-icon>format_align_left</md-icon> Contact Us 
                    </md-button>
                  </template>
                </full-bg-card>
              </div>
            </div>
          </div>
        </div>
        <div class="section text-center">
          <div class="container">
            <div class="md-layout">
              <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
                <h3 class="title">About Us</h3>
                <h5 class="">
                  Bubble Divers was created because we believe the underwater
                  world is a beautiful and place, with so much to discover,
                  experience and conserve. This is why we want to share the
                  wonder of SCUBA diving and the beauty of the underwater world
                  with everyone. With our experienced diving instructors we bring a
                  seamless SCUBA experience.
                </h5>
              </div>
            </div>
          </div>
        </div>
        <certified-by-section></certified-by-section>
        <div class="section">
          <div class="container">
            <testimonial></testimonial>
          </div>
        </div>
        <div class="section">
          <div class="container ml-0">
            <parallax-banner
              :bg-image="ctaParralaxBanner.bgImage"
              :styles="ctaParralaxBanner.styles"
            >
              <div class="container text-center">
                <div class="card">
                  <h1 class="cta-text">
                    What are you waiting for? 
                    </h1>
                    <h3 class="cta-text">
                      Discover a new world just beneath the surface
                    </h3>
                    <md-button
                      href="#contact-us"
                      class="md-primary md-round"
                      style="margin-bottom: 50px;"
                    >
                       Get in touch!
                    </md-button>
                </div>
              </div>
            </parallax-banner>
          </div>
        </div>

        <div id="contact-us" class="section">
          <div class="container">
            <contact-us-section></contact-us-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style >
.cta-text {
  color: white;
  padding-bottom: 2rem;
}

@media only screen and (max-width: 768px) {
  .image-wrapper .pro-badge {
    width: 110px;
    visibility: hidden;
  }
  .wrapper .page-header {
  background-position: -300px center;
}
}
</style>

<script>
import Mixins from "@/plugins/basicMixins";
import ParallaxBanner from "./components/ParallaxBanner.vue";
import {
  FullBgCard,
  // RotatingCard,
  // } from "../components/cards/FullBgCard";
} from "@/components";
import Testimonial from "./sections/Testimonial.vue";
import ContactUsSection from "./sections/ContactUsSection.vue";
import CertifiedBySection from "./sections/CertifiedBySection.vue";

export default {
  name: "Index",
  components: {
    FullBgCard,
    ParallaxBanner,
    Testimonial,
    ContactUsSection,
    CertifiedBySection,
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  bodyClass: "index-page",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/landing/diver-frontpage.webp"),
    },
  },
  data() {
    return {
      infoCards: [
        {
          title: "Experiences",
          body: "We organise some of the most adventurous SCUBA diving trips to world famous diving sites in South Africa and beyond.",
          bgImage: require("@/assets/img/diver-ok.webp"),
          styles: {},
        },
        {
          title: "Courses",
          body: "With over 15 year's of diving experience, our kind, passionate and patient instructors will help you be like a fish in water.",
          bgImage: require("@/assets/img/divers-training.webp"),
          styles: {
            backgroundPositionY: "-100px",
          },
        },
        // {
        //   title: "School Dive Clubs",
        //   body: "With passionate instructors and the latest in digital technology, having a dive club at your school has never been easier. ",
        //   bgImage: require("@/assets/img/diver.webp"),
        //   styles: {},
        // },
      ],
      ctaParralaxBanner: {
        bgImage: require("@/assets/img/turtle.webp"),
        styles: {
          backgroundPositionX: "center",
        },
      },
    };
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    
  },
};
</script>
<style lang="scss">
.section-download {
  .md-button + .md-button {
    margin-left: 5px;
  }
}

.vertical-nav-active {
  display: block;
}

@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}

.mb-0 {
  padding-bottom: 0 !important;
}

#morphing-cards {
  padding-top: 70px;
}
</style>
