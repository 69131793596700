<template>
  <div class="wrapper">
    <!-- Main background -->
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout title-row">
          <div
            class="
              md-layout-item md-size-33 md-small-size-100
              ml-auto
              text-right
            "
          ></div>
        </div>
      </div>
    </parallax>
    <div class="section">
      <div class="container">
        <div class="main main-raised">
          <h2 class="title text-center">SCUBA Courses</h2>
          <hr />
          <!-- Each Course -->
          <div class="card" v-for="course in courses" :key="course.name">
            <div
              class="md-layout"
              style="padding-top: 3rem; padding-bottom: 1rem"
            >
              <div class="md-layout-item md-size-50 md-small-size-100">
                <blog-card
                  :card-image="course.image.source"
                  :styles="course.image.styles"
                >
                  <template slot="cardContent">
                    <h2 class="title">{{ course.name }}</h2>
                    <h4 class="">{{ course.tagline }}</h4>
                    <h3 class="main-price">
                      {{ course.price }}
                    </h3>
                  </template>
                </blog-card>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                
                <collapse
                  :active-tab="1"
                  :collapse="['Description']"
                  icon="keyboard_arrow_down"
                  color-collapse="rose"
                >
                  <template slot="md-collapse-pane-1">
                    <p>
                      {{ course.detail }}
                    </p>
                    <div v-if="course.detail_list" slot="md-collapse-pane-2">
                      <div
                        v-for="detail_item in course.detail_list"
                        :key="detail_item.heading"
                      >
                        <h4>
                          {{ detail_item.heading }}
                        </h4>
                        <ul>
                          <li
                            v-for="line_item in detail_item.items"
                            :key="line_item"
                          >
                            {{ line_item }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </collapse>

                <div class="text-right">
                  <md-button class="md-primary md-round">
                    Inquire now <md-icon>keyboard_arrow_right</md-icon>
                  </md-button>
                </div>
              </div>
              <hr style="margin-top: 5rem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, InfoAreas, ProductCard, BlogCard } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  props: {
    image: {
      type: String,
      default: require("@/assets/img/landing/diver-frontpage.webp"),
    },
  },
  components: {
    Collapse,
    BlogCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "product-page",
  data() {
    return {
      courses: [
        {
          name: "Open Water",
          price: "R5499",
          tagline: "This is ideal for when you want to learn Scuba diving?",
          detail:
            "PADI® Open Water Diver is the first scuba certification level. A highly-trained PADI Instructor will teach you how to scuba dive in a relaxed, supportive learning environment. By the end of the course, you'll have the skills and knowledge to dive at home or abroad and be an ambassador for the underwater world.",
          detail_list: [
            {
              heading: "Take this course if you want to",
              items: [
                "Explore the other 70 percent of our planet",
                "Support ocean protection",
                "See things you've never seen before",
                "Learn from a scuba diving expert",
                "Carry the world's most recognized scuba certification",
              ],
            },
            {
              heading: "Learn how to",
              items: [
                "Be a confident and skilled diver",
                "Assemble and use scuba gear",
                "Manage your buoyancy",
                "Respectfully approach marine life",
                "Handle common problems",
              ],
            },
          ],
          image: {
            source: require("@/assets/img/open-water.webp"),
            styles: {
              minHeight: "400px",
              backgroundColor: "rgba(0, 0, 0, 0.0)",
            },
          },
        },
        {
          name: "Advanced Open Water",
          price: "R4 999",
          tagline: "Keen to take that next step in SCUBA?",
          detail:
            "The Advanced Open Water Diver course is all about advancing your skills. You'll practice navigation and buoyancy, try deep diving and make three specialty dives of your choosing (it's like a specialty sampler platter). For every specialty dive you complete, you can earn credit toward PADI® specialty certifications. Here are a few of the many options: Deep, Digital Underwater Imaging, Dive Against Debris, Dry Suit, Enriched Air Nitrox, Fish Identification, Night, Peak Performance Buoyancy, Search & rescue, Underwater Naturalist, Underwater Navigation, and Wreck Diver.",
          detail_list: [
            {
              heading: "Take this course if you want to",
              items: [
                "Gain more diving experience",
                "Practice navigation",
                "Sample different types of diving",
              ],
            },
            {
              heading: "Learn how to",
              items: [
                "Explore below 18m",
                "Improve your buoyancy",
                "Use a compass",
              ],
            },
          ],
          image: {
            source: require("@/assets/img/advanced-open-water.webp"),
            styles: {
              minHeight: "400px",
              backgroundColor: "rgba(0, 0, 0, 0.0)",
            },
          },
        },
        {
          name: "ReActivate",
          price: "R700",
          tagline:
            "Are you a certified diver? Has it been a while since you have been Scuba diving?",
          detail:
            "The PADI Re-Activate (previously Refresher) is designed for certified divers who want to relearn basic skills and ease into the sport after not diving for a few years. You will be back to feeling comfortable in the water in no time at all",
          image: {
            source: require("@/assets/img/re-activate.webp"),
            styles: {
              minHeight: "400px",
              backgroundColor: "rgba(0, 0, 0, 0.0)",
            },
          },
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.inline-container-zoomer-box {
  .preview-box {
    text-align: center;
    margin-bottom: 5vh;
    margin-top: 2vh;

    img {
      max-width: 60%;
    }
  }

  .control-box {
    margin-bottom: 30px;
  }

  .thumb-list img {
    padding: 18px 8px;
    border: 1px solid transparent;
    opacity: 0.8;
    transition: all 0.15s ease;

    &:hover {
      opacity: 1;
      border-color: #ddd;
    }
  }

  .responsive-image {
    max-width: 80%;
    border-radius: 3px;

    &.choosed-thumb {
      box-shadow: none !important;
      border-color: #ddd;
    }
  }

  .control {
    font-size: 14px;

    path {
      fill: #9fa4ab;
    }
  }
}
// TODO Continue here
.md-card.md-theme-default {
}
</style>
