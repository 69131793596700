<template>
  <!-- <parallax parallax-active="true">
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="image-wrapper">
          <div class="wrapper">
            <img :src="bgImage" />
          </div>
        </div>
      </div>
    </div>
  </parallax> -->
  <!-- <div
    class="parallax-banner"
    :style="`background-image=${this.bgImage} !important`"
  >
    TEST
  </div> -->

  <!-- <div class="parallax-banner" :style="parralaxBannerStyles()"></div> -->
  <div class="parallax-banner" v-bind:style="parralaxBannerStyles()">
    <slot >
    </slot>
  </div>
</template>

<script>
export default {
  name: "ParallaxBanner",
  props: {
    bgImage: {
      type: String,
      default: "",
    },
    styles:{
      type: Object, 
    }
  },
  data() {
    return {
      // bgImage: String,
      // companyName: null,
      // companyEmail: null,
      // companyPassword: null,
      // toggledClass: false,
      // headerImages: {
      //   Image1: require("@/assets/img/bg12.jpg"),
      //   Image2: require("@/assets/img/examples/office2.jpg"),
      //   Image3: require("@/assets/img/dg1.jpg"),
      //   Image4: require("@/assets/img/dg2.jpg"),
      //   Image5: require("@/assets/img/dg3.jpg")
      // }
    };
  },
  methods: {
    parralaxBannerStyles() {
      let banner_styles = this.styles
      banner_styles.backgroundImage= `url(${this.bgImage})`
      banner_styles.backgroundSize= 'cover'
      return banner_styles;
    },
    header(nr) {
      return {
        backgroundImage: `url(${this.headerImages[`Image${nr}`]})`,
      };
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
  },
};
</script>

<style lang="scss">
.parallax-banner {
  // background-image: !important;
  // background-image: ;
  /* Set a specific height */
  min-height: 300px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  
  background-repeat: no-repeat;
  background-size: cover;
}
.buttons {
  display: flex;
  align-items: center;
}

#headers {
  .el-carousel__container {
    height: 100vh !important;
  }
  .el-carousel {
    border-radius: 0 !important;
  }
}

.text-right {
  text-align: right;
}

.border-radius-0 {
  border-radius: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

@media all and (max-width: 960px) {
  .md-card-form-horizontal .md-layout-item {
    margin-top: 1.5rem;
  }
}
</style>
