<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-size-66 md-small-size-100
              mx-auto
              text-center
            "
          >
            <h1 class="title">Contact Us</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div id="contact-us" class="section">
        <div class="container">
          <contact-us-section></contact-us-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUsSection from "./sections/ContactUsSection.vue";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ContactUsSection,
  },
  mixins: [Mixins.HeaderImage],

  data() {
    return {
      image: require("@/assets/img/underwater.jpg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .wrapper .page-header {
  background-position: 0px center;
}
}
</style>
