<template>
  <div class="wrapper">


    <!-- Projects 2 -->
    <div class="section pt-0 pb-0">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 justify-content-center mx-auto text-center"
          >
            
            <h2 class="title">
              Proudly Certified By
            </h2>
            <br v-if="!certificationDescription"/>
            <h5 v-else class="description">
              {{certificationDescription}}
            </h5>
          </div>
        </div>
        <div class="md-layout justify-content-center">
          <div class="md-layout-item md-size-33 md-small-size-100"
            v-for="cc in certifiedCards"
            :key="cc.identifier"
          >
            <profile-card
              card-plain
              :shadow-normal="false"
              :no-colored-shadow="false"
              :image-link="cc.link"
              :card-image="cc.image"
              :external-image-link="cc.externalLink"
            >
              <template slot="cardContent">
                <a
                  v-if="cc.link"
                  :href="cc.link"
                  target="_blank"
                >
                  <h4 class="card-title">{{cc.title}}</h4>
                </a>
                <h6 v-if="cc.subTitle" class="card-category text-muted">
                  {{cc.subTitle}}
                </h6>
                <p v-if="cc.description" class="card-description">
                  {{cc.description}}
                </p>
              </template>
            </profile-card>
          </div>
        </div>
      </div>
    </div>
    <!-- end Projects 2 -->


  </div>
</template>

<script>
import { Tabs, FullBgCard, InfoAreas, Badge, ProfileCard } from "@/components";

export default {
  name: "CertifiedBySection",
  components: {
    // Tabs,
    // FullBgCard,
    ProfileCard,
    // InfoAreas,
    // Badge
  },
  data() {
    return {
      certificationDescription:"",
      certifiedCards:[
        {
          identifier: "Padi",
          image: require("@/assets/img/logo/padi-logo.webp"), 
          title: this.identifier, 
          subTitle: "", 
          description: "",
          link: "https://en.wikipedia.org/wiki/Professional_Association_of_Diving_Instructors",
          externalLink: true, 
        }
      ]
    };
  }
};
</script>

<style lang="css"></style>
