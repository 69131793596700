

import Vue from "vue";
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";

import MaterialKit from "./plugins/material-kit";
import VueGtag from "vue-gtag";


import axios from 'axios'
import VueAxios from 'vue-axios'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
library.add(faInstagram)
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedin)
import { faTiktok } from '@fortawesome/free-brands-svg-icons'
library.add(faTiktok)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueAxios, axios)

Vue.use(VueGtag, {
  config: { id: "G-3SBHEY9D2M" }
});

Vue.config.productionTip = false;

Vue.use(MaterialKit);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
