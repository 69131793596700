<template>
  <div class="card">
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
        <h2 class="title mt-0">Send us a message</h2>
        <form @submit.prevent="submitContactForm()">
          <md-field :class="nameInputClass">
            <label>Your Name</label>
            <md-input
              v-model="form.name"
              type="text"
              required
              aria-required="true"
            />
            <span class="md-error">Your name is required</span>
          </md-field>
          <md-field :class="emailInputClass">
            <label>Email Adress</label>
            <md-input
              v-model="form.email"
              type="email"
              aria-required="true"
              required
            />
            <span class="md-error">A valid email is required</span>
          </md-field>
          <md-field :class="phoneInputClass">
            <label>Phone</label>
            <md-input
              v-model="form.phone"
              type="text"
              aria-required="true"
              required
            />
            <span class="md-error">A valid phone number is Required</span>
          </md-field>
          <md-field :class="messageInputClass">
            <label>Your message</label>
            <md-textarea v-model="form.msg" required aria-required="true" />
            <span class="md-error">Your message is required</span>
          </md-field>

          <div class="submit text-center mt-3">
            <vue-recaptcha
              sitekey="6LfgVHcgAAAAAAD-HFClohqMGtDVIdPVw66DrzQH"
              type="checkbox"
              align="center"
              @verify="verifyRecaptchaMethod"
              @expired="expiredRecaptchaMethod"
            >
            </vue-recaptcha>
            <md-button
              type="submit"
              class="md-primary md-round mt-3"
              :disabled="submit_button_disabled"
            >
              Contact Us&nbsp;&nbsp;
              <md-progress-spinner
                v-if="submit_button_disabled"
                md-mode="indeterminate"
                md-diameter="12"
                md-stroke="2"
              ></md-progress-spinner>
            </md-button>
            <div></div>
          </div>
        </form>
      </div>
      <div class="md-layout-item md-size-33 md-small-size-100 ml-auto">
        <info-areas
          class="pt-0"
          info-horizontal
          icon-color="primary"
          icon="pin_drop"
        >
          <h4 slot="title" class="info-title">Location</h4>
          <div slot="content" class="description">
            <p>
              {{ location.line1 }} <br />
              {{ location.line2 }} <br />
              {{ location.line3 }}
            </p>
          </div>
        </info-areas>
        <info-areas
          class="pt-0"
          info-horizontal
          icon-color="primary"
          icon="phone"
        >
          <a :href="'tel:' + phone_number.link" slot="title" class="info-title">
            <h4 slot="title" class="info-title">
              {{ phone_number.display }}
            </h4> </a
          ><br />
          <div slot="content" class="description">
            <p>{{ phone_number.hours }}</p>
          </div>
        </info-areas>
        <info-areas
          class="pt-0"
          info-horizontal
          icon-color="primary"
          icon="email"
        >
          <a :href="'mailto:' + contact_email" slot="title" class="info-title">
            <h4 slot="title" class="info-title">{{ contact_email }}</h4>
          </a>
        </info-areas>
        <div>
          <a :href="socials.instagram.link" target="_blank">
            <font-awesome-icon icon="fab fa-instagram" />
            {{ socials.instagram.name }} </a
          ><br />
        </div>
        <div>
          <a :href="socials.linkedin.link" target="_blank"
            ><font-awesome-icon icon="fab fa-linkedin" />
            {{ socials.linkedin.name }}</a
          ><br />
        </div>
        <div>
          <a :href="socials.tiktok.link" target="_blank"
            ><font-awesome-icon icon="fab fa-tiktok" />
            {{ socials.tiktok.name }}</a
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { InfoAreas } from "@/components";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { VueRecaptcha } from "vue-recaptcha";

Vue.use(VueAxios, axios);

export default {
  name: "ContactUsSection",
  components: {
    InfoAreas,
    VueRecaptcha,
  },
  bodyClass: "contact-us",
  data() {
    return {
      form: {
        recaptcha_valid: false,
        name: "",
        email: "",
        msg: "",
        phone: "",
        has_messages: true,
        valid: {
          name: true,
          email: true,
          phone: true,
          message: true,
        },
      },
      submit_button_disabled: false,
      location: {
        line1: "Glenvista,",
        line2: "Johannesburg,",
        line3: "South Africa",
      },
      phone_number: {
        display: "+27 72 3719796",
        link: "+27723719796",
        hours: "Mon - Fri, 8:00-17:00",
      },
      contact_email: "info@bubbledivers.co.za",
      socials: {
        instagram: {
          name: "@bubble_divers",
          link: "https://www.instagram.com/bubble_divers/",
        },
        linkedin: {
          name: "@bubble_divers",
          link: "https://www.linkedin.com/company/bubblediversclub",
        },
        tiktok: {
          name: "@bubble_divers",
          link: "https://www.tiktok.com/@bubble_divers",
        },
      },
    };
  },
  methods: {
    verifyRecaptchaMethod() {
      this.form.recaptcha_valid = true;
    },
    expiredRecaptchaMethod() {
      this.form.recaptcha_valid = false;
    },

    validateForm() {
      let has_errors = false;
      if (!this.form.name) {
        this.form.valid.name = false;
        has_errors = true;
      } else {
        this.form.valid.name = true;
      }

      if (!this.form.email) {
        this.form.valid.email = false;
        has_errors = true;
      } else {
        this.form.valid.email = true;
      }
      if (!this.form.phone) {
        this.form.valid.phone = false;
        has_errors = true;
      } else {
        this.form.valid.phone = true;
      }
      if (!this.form.msg) {
        this.form.valid.message = false;
        has_errors = true;
      } else {
        this.form.valid.message = true;
      }

      if (!this.form.recaptcha_valid) {
        has_errors = true;
      }
      return has_errors
    },
    submitContactForm() {
      this.submit_button_disabled = true;
      if (this.validateForm()){
        this.submit_button_disabled = false;
        return 
      }
      
      let post_data = {
        src: "91907c56-c4bc-411c-a785-1e036a05762e",
        type: "contact_form",
        content: this.form,
        key: "7407ac52-7d03-4858-a9a3-0b84d8f02714",
      };
      Vue.axios
        .post(
          "https://4k3plhijoj.execute-api.af-south-1.amazonaws.com/prod/contact-form",
          post_data
        )
        .then((res) => {
          this.submit_button_disabled = false;
        });

    },
  },
  computed: {
    nameInputClass() {
      return {
        "md-invalid": !this.form.valid.name,
      };
    },
    emailInputClass() {
      return {
        "md-invalid": !this.form.valid.email,
      };
    },
    phoneInputClass() {
      return {
        "md-invalid": !this.form.valid.phone,
      };
    },
    messageInputClass() {
      return {
        "md-invalid": !this.form.valid.message,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-3 {
  margin-top: 1.5rem;
}
</style>
