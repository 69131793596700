<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <template
      v-if="$route.name !== 'ecommerce-page' && $route.name !== 'presentation'"
    >
      <div class="container">
        <nav>
          <ul>
            <li 
            v-for="footerLink in footerLinks"
            :key="footerLink.display"
            >
              <a v-if="!footerLink.routerLink" :href="footerLink.link">
                {{footerLink.display}}
              </a>
              <router-link v-else :to="footerLink.routerLink">{{footerLink.display}}</router-link>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, Bubble Divers, South Africa
        </div>
      </div>
    </template>

  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      footerLinks:[
        {
          display:"About Us", 
          // link:"/about-us", 
          routerLink:"about-us", 
        },
        {
          display:"Contact Us", 
          // link:"/contact-us", 
          routerLink:"contact-us", 
        },
        {
          display:"Courses", 
          // link:"/courses", 
          routerLink:"courses", 
        },
     
      ], 

    };
  }
};
</script>
<style></style>
