
<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-size-66 md-small-size-100
              mx-auto
              text-center
            "
          >
            <h1 class="title">About Us</h1>
            <h4>SCUBA is our way of life</h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description text-center ">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h5 class="description">
                Bubble Divers was created because we believe the underwater
                world is such a beautiful place to discover, experience
                and conserve. This is why we want to share the wonder of SCUBA
                diving and the beauty of the underwater playground with the world.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="section section-about-team section-team-1 pb-0">
        <div class="container">
          <div class="md-layout">
            <div
              class="
                md-layout-item md-size-66 md-small-size-100
                mx-auto
                text-center
              "
            >
              <h2 class="title">We are nerd rockstars</h2>
              <h5 class="description">
                This is the paragraph where you can write more details about
                your team. Keep you user engaged by providing meaningful
                information.
              </h5>
            </div>
          </div>
          <div class="md-layout justify-content-center">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="aboutTeam.aboutTeam1"
              >
                <template slot="cardContent">
                  <h4 class="card-title">Alec Thompson</h4>
                  <h6 class="card-category text-muted">Ceo/Co-founder</h6>
                  <p class="card-description">
                    And I love you like Kanye loves Kanye. We need to restart
                    the human foundation.
                  </p>
                </template>
                <template slot="cardAction">
                  <md-button
                    href="javascript:void(0)"
                    class="md-twitter md-just-icon md-simple"
                  >
                    <i class="fab fa-twitter" />
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-facebook md-just-icon md-simple"
                  >
                    <i class="fab fa-facebook" />
                  </md-button>
                  <md-button
                    href="javascript:void(0)"
                    class="md-google md-just-icon md-simple"
                  >
                    <i class="fab fa-google" />
                  </md-button>
                </template>
              </profile-card>
            </div>
            
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ProfileCard, InfoAreas } from "@/components";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    // ProfileCard,
    // InfoAreas,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      speciality: "",
      name: null,
      email: null,
      image: require("@/assets/img/underwater.jpg"),

    };
  },
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .wrapper .page-header {
  background-position: 0px center;
}
}
</style>
